// #Nav3_0 .header3-menu > .ant-menu > .l9ipxd7hakc-editor_css {
  
// }

#Teams1_0 .ant-row > .ant-col > .l9jk0g0p7wg-editor_css {
  border-top-left-radius: 100px;
  border-top-right-radius: 10px;
  border-radius: 100px;
}
// #Nav3_0 .l9ipx5017mn-editor_css {
// }
#Content11_0 .title-wrapper > .l9jmpevze4s-editor_css {
  font-size: 6em;
  font-family: 'Courier New', Courier, monospace
}
#Content11_0 .title-wrapper > .l9jmpi9mzsq-editor_css {
  font-size: 1.8em;
}
#Content11_0 .title-wrapper > .l9jmpjn3x0r-editor_css {
  font-size: 1.5em;
}
// #Content11_0 div > .l9jmplkyrjb-editor_css {
//   display: none;
// }
// #Content11_0 .title-wrapper > .l9jmty0otx-editor_css {
//   display: none;
// }
// #Content11_0.l9jkfvphvsr-editor_css {
//   // height: 550px;
//   // background-image: url('https://github.com/dabluu-tech/dabluu-site-assets/raw/main/network.svg');
//   // background-clip: padding-box, padding-box;
// }
// #Banner5_0.l9jovv7cyj-editor_css {
//   background-color: rgba(0, 0, 0, 0);
// }
// #Banner5_0 .l9ipwvg9bit-editor_css {
//   background-color: rgba(0, 0, 0, 0);
// }
// #Feature4_0 .l9kvtdact7-editor_css {
//   align-items: stretch;
// }

.banner5-inline-image {
  height: 30px;
  margin-bottom: 4px;
}



@logo-title: logo-title;

.@{logo-title} {
  font-family: 'Audiowide';
  font-weight: 400;
}

.@{logo-title}.da{
  color: #F907E0;
}

.@{logo-title}.team {
  color: #1155CC;
}

@media screen and (max-width: 767px) {
  .banner5-inline-image {
    height: 24px;
  }
}