@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Bariol Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Bariol Bold'), url('../public/fonts/Bariol_Bold.woff') format('woff');
}

@font-face {
  font-family: 'Audiowide';
  font-style: normal;
  font-weight: normal;
  src: local('Audiowide'), url('../public/fonts/Audiowide-Regular.woff2') format('woff2');
}

body {
  margin: 0;
  font-family:'Ubuntu', 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Helvetica Neue','Fira Sans', 'Droid Sans',
     -apple-system,BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@primary-color: #1DA57A;